<script setup>
import { onBeforeMount, ref } from 'vue';
import get from 'lodash/get';
import { Validate as vValidate } from '@emobg/vue-base';
import { VALIDATION_PATTERN } from '@emobg/web-utils';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import { useTheme } from '@/composable/Theme/useTheme';
import * as BookingStore from '@/vue/stores/Booking/BookingStore';

const emits = defineEmits(['close-modal']);

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const mileage = ref('');
const isFormValid = ref(false);

const { estimatedTripMileage, setEstimatedTripMileage } = useStoreModule(BookingStore.nameSpace, {
  state: {
    estimatedTripMileage: state => get(state, 'estimatedTripMileage'),
  },
  mutations: {
    setEstimatedTripMileage: BookingStore.MUTATIONS.SET_ESTIMATED_TRIP_MILEAGE,
  },
});

const { fetchButtonSpecs } = useTheme();

const saveEstimatedDistance = () => {
  setEstimatedTripMileage(mileage.value);
  emits('close-modal');
};

onBeforeMount(() => {
  mileage.value = estimatedTripMileage.value;
});
</script>
<template>
  <ui-modal
    :header="$t('Bookings.DistanceFilter.modal.title')"
    :size="SIZES.small"
    :open="props.isVisible"
    :closable="false"
    @close="emits('close-modal')"
  >
    <div slot="body">
      <p class="my-3">
        {{ $t('Bookings.DistanceFilter.modal.text') }}
      </p>
      <ui-validate @status="({ detail }) => isFormValid = detail.isValid">
        <ui-text-input
          v-validate.input="{
            isRequired: {
              value: true,
              message: $t('Bookings.DistanceFilter.modal.error_mandatory'),
              whiteSpaceMessage: $t('Bookings.DistanceFilter.modal.error'),
            },
            isPattern: {
              pattern: VALIDATION_PATTERN.positiveInteger,
              message: $t('Bookings.DistanceFilter.modal.error'),
            },
          }"
          :value="mileage"
          label="Km"
          placeholder="0"
          name="mileage"
          @changevalue="({ detail }) => mileage = detail"
        />
      </ui-validate>
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
    >
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="!isFormValid || !mileage"
        @clickbutton="saveEstimatedDistance"
      >
        {{ $t('Bookings.DistanceFilter.modal.button') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
