<template>
  <FeedbackModalComponent
    data-test-id="waiting-for-validation-component"
    :is-open="isOpen"
    :image="errorImage"
    :title="title"
    :description="description"
    :primary-call-to-action-text="$t('buttons.got_it')"
    :primary-call-to-action="() => $emit('update:is-open', false)"
  />
</template>

<script>
import get from 'lodash/get';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { waitingForValidationError } from '@/utils/publicImages';
import config from '@/config';

export default {
  name: 'WaitingForValidationModalComponent',

  components: {
    FeedbackModalComponent,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    operator: {
      type: Object,
      default: () => ({}),
    },
  },

  created() {
    const phone = get(this, 'operator.customer_service_phone', '');
    const workingHours = get(this, 'operator.customer_service_opening_hours', '');
    const operator = config.data.appName;

    this.errorImage = waitingForValidationError;
    this.description = this.$t('BookingView.WaitingForValidationModalComponent.description', {
      phone,
      workingHours,
      operator,
    });
  },
};
</script>
