<template>
  <article class="phLocation phLocation-placeholder">
    <header class="phLocation_header">
      <div class="phLocation_icon" />
      <div class="phLocation_name" />
    </header>
    <template v-for="index in 4">
      <VehiclePlaceholder :key="index" />
    </template>
  </article>
</template>

<script>
import VehiclePlaceholder from '../VehiclePlaceholder/VehiclePlaceholder';

export default {
  name: 'LocationPlaceholder',
  components: {
    VehiclePlaceholder,
  },
};

</script>
