<template>
  <section
    class="
      BookingOnBehalfComponent
      row no-gutters align-items-center
      flex-grow-1
    "
    data-test-id="booking_on_behalf"
  >
    <div class="col-sm-6 pb-2 pb-sm-0">
      <h3 class="pb-1 emobg-color-white">
        {{ $t('views.home.title') }}
      </h3>

      <p class="emobg-body-small emobg-color-white">
        {{ $t('views.home.subtitle') }}
      </p>
    </div>

    <MuiAlgoliaSelect
      :model="selectedEmployeeUuid"
      :title="getFullName"
      :placeholder="$t('views.home.booking_on_behalf_placeholder')"
      :is-remove-selection-enabled="false"
      :filters="activeEmployeeFilterQuery"
      class="mb-0 col-sm-6"
      data-test-id="employee-selector"
      index="employees"
      name="employees"
      path-value="uuid"
      :size="SIZES.large"
      @change="value => $emit('change', value)"
      @selected="employee => $emit('on-selected-employee', employee)"
    >
      <template #item="{ item }">
        <BookingOnBehalfItemComponent
          :data-test-id="`booking_on_behalf-item-${item.algoliaItem.uuid}`"
          :label="getFullName(item.algoliaItem)"
          :email="item.algoliaItem.email"
          :external-reference="item.algoliaItem.external_reference"
        />
      </template>
    </MuiAlgoliaSelect>
  </section>
</template>

<script>
import get from 'lodash/get';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import { aclService } from '@emobg/access-utils';

import { concatFilterByPermission, createFilterByPermissions } from '@/utils/algoliaHelpers';
import { CREATE_BOOKING_ON_BEHALF_EMPLOYEES } from '@/constants/permissions';
import { EMPLOYEE_STATUS } from '@/constants/employeeStatus';

import BookingOnBehalfItemComponent from './BookingOnBehalfItemComponent';
import { getFullName } from './get-full-name-helper';

export default {
  name: 'BookingOnBehalfComponent',

  components: {
    MuiAlgoliaSelect,
    BookingOnBehalfItemComponent,
  },

  props: {
    selectedEmployeeUuid: {
      required: true,
      type: String,
    },
    userData: {
      required: true,
      type: Object,
    },
  },

  computed: {
    activeEmployeeFilterQuery() {
      const circlesUuids = createFilterByPermissions(
        aclService,
        CREATE_BOOKING_ON_BEHALF_EMPLOYEES,
      );

      return concatFilterByPermission({
        currentFilter: `company_uuid:'${get(this, 'userData.company.uuid')}' AND employee_status:'${EMPLOYEE_STATUS.activated}'`,
        circlesUuids,
        field: 'circles',
        extraCircleCondition: ` OR uuid:'${get(this, 'userData.uuid')}'`,
      });
    },
  },

  methods: {
    getFullName,
  },
};
</script>
