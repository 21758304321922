import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import startCase from 'lodash/startCase';

export const getFullName = result => {
  if (!isPlainObject(result)) {
    return '';
  }

  const firstName = get(result, 'user.first_name', result.user_first_name) || '';
  const lastName = get(result, 'user.last_name', result.user_last_name) || '';

  return startCase(`${firstName} ${lastName}`);
};
