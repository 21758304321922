<template>
  <div
    :class="{
      'phDatetimepicker_box--active': isActive,
      'phDatetimepicker_box--disabled': disabled,
    }"
    class="phDatetimepicker_box"
  >
    <ui-button
      :face="FACES.text"
      :disabled="disabled"
      data-test-id="substract-button"
      square
      type="button"
      @clickbutton="subtract"
    >
      <ui-icon
        :color="GRAYSCALE.inkLight"
        :icon="ICONS.arrowLeft"
        :size="SIZES.xSmall"
      />
    </ui-button>
    <span :class="`phDatetimepicker_widget-${position}`">
      <input
        ref="input"
        :class="className"
        :disabled="disabled"
        size="11"
        class="phDatetimepicker_input text-capitalize"
        type="text"
      >
    </span>
    <ui-button
      :face="FACES.text"
      :disabled="disabled"
      data-test-id="add-button"
      square
      type="button"
      @clickbutton="add"
    >
      <ui-icon
        :color="GRAYSCALE.inkLight"
        :icon="ICONS.arrowRight"
        :size="SIZES.xSmall"
      />
    </ui-button>
  </div>
</template>

<script>
import head from 'lodash/head';
import nth from 'lodash/nth';

// Constants
import { SEGMENT_EVENTS } from '@/vue/constants';

import { useSegment } from '@/composable/Segment/segment';

require('@/assets/js/bootstrap-datetimepicker');

export default {
  name: 'Datetimepicker',

  props: {
    min: {
      type: [Object, Boolean],
      default: undefined,
    },
    initial: {
      type: Object,
      default: () => ({}),
    },
    max: {
      type: [Object, Boolean],
      default: undefined,
    },
    stepping: {
      type: [String, Number],
      default: undefined,
    },
    format: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    btnQuantity: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: 'es',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blurOnChange: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackSegment } = useSegment();
    return { trackSegment };
  },

  data() {
    return {
      className: null,
      changingByProps: false,
      isActive: false,
      howManyQuantity: null,
      unitQuantity: null,
    };
  },

  computed: {
    datetimepicker() {
      return $(`.${this.className}`).data('DateTimePicker');
    },
  },

  watch: {

    min: {
      handler(val) {
        if (this.datetimepicker) {
          this.changingByProps = true;
          this.datetimepicker.minDate(val);
          this.changingByProps = false;
        }
      },
    },

    initial: {
      handler(val) {
        if (this.datetimepicker) {
          this.changingByProps = true;
          this.datetimepicker.date(val);
          this.changingByProps = false;
        }
      },
    },

    max: {
      handler(val) {
        if (this.datetimepicker) {
          this.changingByProps = true;
          this.datetimepicker.maxDate(val);
          this.changingByProps = false;
        }
      },
    },

  },

  created() {
    this.position = this.position || 'left';
    this.className = `js-datetimepicker-${(Math.random() * 1000).toFixed()}`;

    if (this.btnQuantity) {
      this.howManyQuantity = head(this.btnQuantity);
      this.unitQuantity = nth(this.btnQuantity, 1);
    }
  },

  mounted() {
    const self = this;

    this.$nextTick(() => {
      $(`.${self.className}`).datetimepicker({
        locale: this.language,
        showTodayButton: false,
        stepping: this.stepping,
        useCurrent: false,
        format: self.format,
        extraFormats: ['YYYY-MM-DD HH:mm:ss'],
        defaultDate: self.initial,
        minDate: self.min,
        maxDate: self.max,
        widgetParent: $(`.${self.className}`).parent(),
        widgetPositioning: {
          horizontal: 'left',
        },
        icons: {
          time: 'icons-time',
          date: 'icons-calendar',
          up: 'icons-arrow-up',
          down: 'icons-arrow-down',
          previous: 'icons-arrow-left',
          next: 'icons-arrow-right',
          today: 'icons-gps',
          clear: 'icons-trash',
        },
      })
        .on('dp.change', event => {
          if (!self.changingByProps) {
            self.$emit('changing', {
              id: self.id,
              date: event.date || this.initial,
            });
            if (self.blurOnChange) {
              this.$refs.input.blur();
            }
          }
        })
        .on('dp.show', () => {
          self.isActive = true;
          this.trackSegment({
            name: SEGMENT_EVENTS.FILTER_DATE_OPENED,
          });
        })
        .on('dp.hide', () => {
          self.isActive = false;
        });

      this.datetimepicker.date(this.initial);
    });
  },

  methods: {

    add() {
      if (this.disabled) {
        return;
      }
      try {
        const quantity = this.datetimepicker.date();

        quantity.add(this.howManyQuantity, this.unitQuantity);

        this.datetimepicker.date(quantity);
      } catch (error) {
        // Nothing to do here
      }
    },

    subtract() {
      if (this.disabled) {
        return;
      }
      try {
        const quantity = this.datetimepicker.date();

        quantity.subtract(this.howManyQuantity, this.unitQuantity);

        this.datetimepicker.date(quantity);
      } catch (error) {
        // Nothing to do here
      }
    },

  },
};

</script>
