import moment from 'moment';
import Rules from './BookingRules';

export default class MyBookingsRules {
  constructor(config) {
    const bookingRulesConfig = config || {
      getCarsharingMinBookingDuration: () => 1440,
      getCarsharingMaxBookingDuration: () => 0,
      getNextStart: () => moment('1990-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss'),
      start: moment().subtract(10, 'Y').startOf('d'),
      end: moment().startOf('d'),
      getCarsharingDefaultDuration: () => 1440,
      getCarsharingBookingGap: () => 1440,
    };

    this.config = bookingRulesConfig;

    this.rules = new Rules(bookingRulesConfig);

    this.minimumStart = moment(this.config.getNextStart()).subtract(1, 'second');

    this.start = bookingRulesConfig.start || this.config.getNextStart();

    this.maximumStart = false;

    this.nextStart = this.config.getNextStart();

    this.minimumEnd = this.getRules().getMinimumEnd(this.start);

    const defaultEnd = (() => {
      const defaultDuration = this.config.getCarsharingDefaultDuration();
      const minimumDuration = this.config.getCarsharingMinBookingDuration();

      // First configuration of datetimepickers has to be the default
      // configuration, but it can't be less than minimum duration
      if (defaultDuration >= minimumDuration) {
        return moment(this.start).add(defaultDuration, 'm');
      }

      return moment(this.start).add(minimumDuration, 'm');
    })();

    if (bookingRulesConfig.end) {
      if (moment(bookingRulesConfig.end).isBetween(this.getRules().getMinimumEnd(this.start),
        this.getRules().getMaximumEnd(moment()))) {
        this.end = bookingRulesConfig.end;
      } else {
        this.end = defaultEnd;
      }
    } else {
      this.end = defaultEnd;
    }

    this.maximumEnd = this.getRules().getMaximumEnd(moment());
  }

  getNextStart() {
    if (this.nextStart) {
      return this.nextStart;
    }

    const now = moment();
    const gap = this.config.getCarsharingBookingGap();
    const offset = gap - (now.minutes() % gap);
    now.add(offset, 'm').seconds(0).milliseconds(0);
    this.nextStart = now;

    return this.getNextStart();
  }

  getGap() {
    return this.config.getCarsharingBookingGap();
  }

  getMinimumStart() {
    return this.minimumStart;
  }

  setMinimumStart(val) {
    this.minimumStart = val;
  }

  getStart() {
    return this.start;
  }

  setStart(val) {
    this.start = val;
  }

  getMaximumStart() {
    return this.maximumStart;
  }

  setMaximumStart(val) {
    this.maximumStart = val;
  }

  getMinimumEnd() {
    return this.minimumEnd;
  }

  setMinimumEnd(val) {
    this.minimumEnd = val;
  }

  getEnd() {
    return this.end;
  }

  setEnd(val) {
    this.end = val;
  }

  getMaximumEnd() {
    return this.maximumEnd;
  }

  setMaximumEnd(val) {
    this.maximumEnd = val;
  }

  getRules() {
    return this.rules;
  }
}
