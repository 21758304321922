<script>
import { COLORS, GRAYSCALE, ICONS } from '@emobg/web-components';
import { REQUIRED_DOCUMENT_STATUS } from '@/constants/userValidation';

const ICON_COLOR_BY_STATUS = {
  [REQUIRED_DOCUMENT_STATUS.missing]: GRAYSCALE.ground,
  [REQUIRED_DOCUMENT_STATUS.aboutToExpiry]: COLORS.warning,
};

const ICON_BY_STATUS = {
  [REQUIRED_DOCUMENT_STATUS.aboutToExpiry]: ICONS.alertFull,
};

export default {
  name: 'RequiredDocumentsComponent',

  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.NUMBER_OF_SKELETON_LOADERS = 2;
  },

  methods: {
    getIconColor(documentParameter = {}) {
      return ICON_COLOR_BY_STATUS[documentParameter.status] || COLORS.success;
    },

    getIcon(documentParameter = {}) {
      return ICON_BY_STATUS[documentParameter.status] || ICONS.check;
    },
  },
};
</script>

<template>
  <ul data-test-id="required_documents-list">
    <template v-if="isLoading">
      <ui-skeleton
        :rows="NUMBER_OF_SKELETON_LOADERS"
        height="40"
        class="d-block mb-3"
      />
    </template>
    <template v-else>
      <li
        v-for="(documentObject, index) in documents"
        :key="index"
        :data-test-id="`${documentObject.key}-item`"
        class="d-flex mb-3"
      >
        <ui-icon
          :size="ICONS_SIZES.medium"
          :icon="getIcon(documentObject)"
          :color="getIconColor(documentObject)"
          :data-test-id="`${getIconColor(documentObject)}-icon`"
          class="mr-2"
        />
        <div>
          <p
            v-if="documentObject.text"
            data-test-id="text"
            class="mb-0"
          >
            {{ documentObject.text }}
          </p>
          <caption
            v-if="documentObject.caption"
            class="d-block py-0 emobg-color-ink-light"
            data-test-id="caption"
          >
            {{ documentObject.caption }}
          </caption>
        </div>
      </li>
    </template>
  </ul>
</template>
