<template>
  <div
    :class="[
      'phDatetimepicker_wrapper',
      {
        'flex-column': isVertical,
      },
    ]"
  >
    <div
      :class="[
        'phDatetimepicker',
        {
          'my-4 w-100': isVertical,
        }
      ]"
    >
      <label
        class="phDatetimepicker_label"
        for=""
      >
        {{ $t('new_booking.picker.start') }}
      </label>
      <div class="phDatetimepicker_date">
        <Datetimepicker
          id="startDate"
          :btn-quantity="['1', 'day']"
          :disabled="isBookingStarted"
          :initial="datetimepickerManager.getStart()"
          :language="currentLanguage"
          :max="datetimepickerManager.getMaximumStart()"
          :min="datetimepickerManager.getMinimumStart()"
          :stepping="datetimepickerManager.getStepping()"
          blur-on-change
          format="DD MMM YY"
          position="left"
          data-test-id="start_date"
          @changing="debouncedStartDate"
        />

        <div class="phDatetimepicker_separator" />

        <Datetimepicker
          id="startTime"
          :btn-quantity="[datetimepickerManager.getStepping(), 'minutes']"
          :disabled="isBookingStarted"
          :initial="datetimepickerManager.getStart()"
          :language="currentLanguage"
          :max="datetimepickerManager.getMaximumStart()"
          :min="datetimepickerManager.getMinimumStart()"
          :stepping="datetimepickerManager.getStepping()"
          format="HH:mm"
          position="right"
          data-test-id="start_time"
          @changing="debouncedStartDate"
        />
      </div>
    </div>

    <div
      :class="[
        'phDatetimepicker mt-2 mt-md-0',
        {
          'my-4 w-100': isVertical,
        }
      ]"
    >
      <label
        class="phDatetimepicker_label"
        for=""
      >
        {{ $t('new_booking.picker.end') }}
      </label>

      <div class="phDatetimepicker_date">
        <Datetimepicker
          id="endDate"
          :btn-quantity="['1', 'day']"
          :disabled="disabled"
          :initial="datetimepickerManager.getEnd()"
          :language="currentLanguage"
          :max="datetimepickerManager.getMaximumEnd()"
          :min="minimumEnd"
          :stepping="datetimepickerManager.getStepping()"
          blur-on-change
          format="DD MMM YY"
          position="left"
          data-test-id="end_date"
          @changing="debouncedEndDate"
        />

        <div class="phDatetimepicker_separator" />

        <Datetimepicker
          id="endTime"
          :btn-quantity="[datetimepickerManager.getStepping(), 'minutes']"
          :disabled="disabled"
          :initial="datetimepickerManager.getEnd()"
          :language="currentLanguage"
          :max="datetimepickerManager.getMaximumEnd()"
          :min="minimumEnd"
          :stepping="datetimepickerManager.getStepping()"
          format="HH:mm"
          position="right"
          data-test-id="end_time"
          @changing="debouncedEndDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { SPEED } from '@emobg/web-utils';

import UserDataMixin from '@/mixins/UserData';
import EventHandlerMixin from '@/mixins/EventHandler';
import CSOperatorMixin from '@/mixins/CSOperator';
import { useSegment } from '@/composable/Segment/segment';

import languageService from '@/services/Language';
import {
  SEGMENT_ENUMERATE_VALUES,
  SEGMENT_EVENTS,
  SEGMENT_PARAM_NAMES,
} from '@/vue/constants';
import { isEndBetweenRules, toBookingTimer } from '@/helpers/booking/bookingTimeHelpers';
import Datetimepicker from './Datetimepicker/Datetimepicker';

export default {
  components: {
    Datetimepicker,
  },

  mixins: [
    EventHandlerMixin,
    UserDataMixin,
    CSOperatorMixin,
  ],

  props: {
    datetimepickerManager: {
      type: Object,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { trackSegment } = useSegment();
    return { trackSegment };
  },

  computed: {
    currentLanguage() {
      return languageService.getLanguage();
    },
    isBookingStarted() {
      const isPast = this.datetimepickerManager.getStart().isSameOrBefore(this.datetimeBookBefore);
      const isBookingStarted = this.isUpdate && isPast;

      return this.disabled || isBookingStarted;
    },
    minimumEnd() {
      const isAfterMinEnd
        = this.datetimeCreated.isAfter(this.datetimepickerManager.getMinimumEnd());

      if (this.isUpdate && isAfterMinEnd) {
        const shouldBeFinished
          = this.datetimeCreated.isSameOrAfter(this.datetimepickerManager.getEnd());

        const gap = this.datetimepickerManager.getGap();
        const offset = gap - (this.datetimeCreated.minutes() % gap);
        const nextEnd = moment(this.datetimeCreated).add(offset, 'm').seconds(0).milliseconds(0);

        return shouldBeFinished ? this.datetimepickerManager.getEnd() : nextEnd;
      }
      return this.datetimepickerManager.getMinimumEnd();
    },
  },

  created() {
    const operatorConfig = get(this, 'datetimepickerManager.config.configuration');
    this.MINUTES_BOOK_BEFORE = get(operatorConfig, 'minutes_to_book_before_current_time', 0);
    this.datetimeCreated = moment();
    this.datetimeBookBefore = moment(this.datetimeCreated).subtract(this.MINUTES_BOOK_BEFORE, 'minutes');
  },

  mounted() {
    this.datetimepickerManager.getMinimumStart().subtract(this.MINUTES_BOOK_BEFORE, 'minutes');
  },

  methods: {
    debouncedStartDate: debounce(function debounceStartDateChange(date) {
      this.startDateChange(date);
    }, SPEED.medium),

    debouncedEndDate: debounce(function debounceEndDateChange(date) {
      this.endDateChange(date);
    }, SPEED.medium),
    startDateChange({ date }) {
      // FIX: Update Datetimepicker to don't emit or use watcher instead
      const previousStart = moment(this.datetimepickerManager.getStart());
      this.datetimepickerManager.start = moment(date);

      this.datetimepickerManager.setMinimumEnd(false);
      this.datetimepickerManager.setMaximumEnd(false);

      this.$nextTick(() => {
        const minimumEnd = this.datetimepickerManager.getRules().getMinimumEnd(date);
        const maximumEnd = this.datetimepickerManager.getRules().getMaximumEnd(date);

        this.datetimepickerManager.setMinimumEnd(minimumEnd);
        this.datetimepickerManager.setMaximumEnd(maximumEnd);

        const bookingTimer = toBookingTimer(this.datetimepickerManager);

        // If End date is not between min date and max date, set a new end date
        if (bookingTimer && !isEndBetweenRules(bookingTimer)) {
          this.datetimepickerManager.setEnd(this.datetimepickerManager.getRules().getEnd(date));
        }

        this.trackDateChange();

        if (!previousStart.isSame(this.datetimepickerManager.getStart())) {
          this.$emit('change-datetimepicker-values');
        }
        this.eventHandler.$emit(this.events.CHANGE_DATETIMEPICKER, this.datetimepickerManager);
      });
    },

    endDateChange({ date }) {
      // FIX: Update Datetimepicker to don't emit or use watcher instead
      const previousEnd = moment(this.datetimepickerManager.getEnd());
      this.datetimepickerManager.setEnd(moment(date));

      this.trackDateChange();

      if (!previousEnd.isSame(this.datetimepickerManager.getEnd())) {
        this.$emit('change-datetimepicker-values');
      }

      this.eventHandler.$emit(this.events.CHANGE_DATETIMEPICKER, this.datetimepickerManager);
    },

    trackDateChange() {
      const numHoursDiff = moment.duration(
        this.datetimepickerManager.end.diff(this.datetimepickerManager.start),
      ).asHours();

      this.trackSegment({ name: SEGMENT_EVENTS.FILTER_DATE_SELECTED,
        data: {
          [SEGMENT_PARAM_NAMES.NUM_HOURS]: numHoursDiff,
          [SEGMENT_PARAM_NAMES.COMPONENT_VIEW]: SEGMENT_ENUMERATE_VALUES.COMPONENT_VIEW.MAP,
        } });
    },
  },
};

</script>
