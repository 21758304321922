<template>
  <div class="BookingOnBehalfItem w-100 mb-1 ellipsis">
    <div class="emobg-body-small item-label">
      <span data-test-id="label-text">{{ label }}</span>
      <span
        v-if="externalReference"
        data-test-id="external_reference-text"
        class="overflow-hidden emobg-color-ink-light emobg-body-x-small external-reference"
      >
        {{ $t('views.home.booking_on_behalf_refernce_label') }} {{ externalReference }}
      </span>
    </div>

    <div
      v-if="email"
      class="emobg-color-ink-light emobg-body-x-small"
      data-test-id="email-text"
    >
      {{ email || FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingOnBehalfItem',
  props: {
    label: {
      required: true,
      type: String,
    },
    email: {
      type: String,
      default: () => null,
    },
    externalReference: {
      type: String,
      default: () => null,
    },
  },
};
</script>

<style lang="scss">
@import "~@emobg/sass/bootstrap/variables";

.BookingOnBehalfItem {
  .item-label {
    display: flex;
    justify-content: space-between;
    height: map-get($spacers, 4);
    padding: map-get($spacers, 2) 0;

    .external-reference {
      max-width: 45%;
    }
  }
}
</style>
