<template>
  <ui-modal
    :open="isOpen"
    :size="SIZES.medium"
    :header="title"
    data-test-id="corporate_validation-modal"
  >
    <div slot="body">
      {{ text }}

      <RequiredDocumentsComponent
        :documents="requiredDocumentsWithTextAndStatus"
        :is-loading="isRequiredDocumentsLoading"
        class="mt-4"
      />
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        data-test-id="continue-button"
        @clickbutton="onClickContinue"
      >
        {{ $t('buttons.got_it') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT } from '@emobg/web-utils';
import {
  fetchRequiredDocuments,
  isRequiredDocumentsLoading,
  requiredDocuments,
} from '@/stores/UserValidations/UserValidationsMapper';
import { REQUIRED_DOCUMENT_STATUS, REQUIRED_DOCUMENT_TYPES } from '@/constants/userValidation';
import RequiredDocumentsComponent from './components/RequiredDocuments/RequiredDocumentsComponent';

export default {
  name: 'CorporateValidationComponent',

  components: {
    RequiredDocumentsComponent,
  },

  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedLocation: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      formattedDate: null,
      text: '',
      title: '',
    };
  },

  computed: {
    requiredDocuments,
    isRequiredDocumentsLoading,

    requiredDocumentsWithTextAndStatus() {
      const isAboutToExpiry = documentToCheck => Boolean(
        documentToCheck.type === REQUIRED_DOCUMENT_TYPES.drivingLicenseReview
        && documentToCheck.status === REQUIRED_DOCUMENT_STATUS.completed
        && this.formattedDate,
      );

      return map(this.requiredDocuments, requiredDocument => ({
        ...requiredDocument,
        key: requiredDocument.type,
        text: this.$t(`BookingView.CorporateValidationComponent.${requiredDocument.type}`),
        status: isAboutToExpiry(requiredDocument) ? REQUIRED_DOCUMENT_STATUS.aboutToExpiry : requiredDocument.status,
      }));
    },
  },

  async created() {
    // Used in /v4/availability
    const drivingLicenseExpiresSoon = get(this.selectedLocation, 'user_documents.driving_license_review_expires_soon');
    const nextDrivingLicenseReviewDate = get(this.selectedLocation, 'user_documents.next_driving_license_review_date');

    if (drivingLicenseExpiresSoon) {
      this.formattedDate = moment(nextDrivingLicenseReviewDate).format(DATE_FORMAT.dob);
      this.title = this.$t('BookingView.CorporateValidationComponent.title_about_to_expiry');
      this.text = this.$t('BookingView.CorporateValidationComponent.about_to_expiry_text', { expirationDate: this.formattedDate });
    } else {
      this.formattedDate = null;
      this.title = this.$t('BookingView.CorporateValidationComponent.title_incomplete');
      this.text = this.$t('BookingView.CorporateValidationComponent.incomplete_text');
    }

    if (isEmpty(this.requiredDocuments)) {
      fetchRequiredDocuments({
        csOperatorUuid: get(this.selectedLocation, 'cs_operator_uuid'),
      });
    }
  },

  methods: {
    onClickContinue() {
      this.$emit('update:is-open', false);
    },
  },
};
</script>
