import moment from 'moment';

export default class Rules {
  constructor(config) {
    this.config = config;
  }

  getMinimumEnd(start) {
    const minimumDuration = this.config.getCarsharingMinBookingDuration();

    return moment(start).add(minimumDuration, 'm').subtract(1, 'second');
  }

  getEnd(start) {
    const minimumDuration = this.config.getCarsharingMinBookingDuration();

    return moment(start).add(minimumDuration, 'm');
  }

  getMaximumEnd() {
    return moment().add(this.config.getCarsharingMaxBookingDuration(), 'm');
  }
}
