<template>
  <div class="row mx-0 mb-2 p-3 emobg-shadow-s emobg-border-radius-large">
    <div class="col-3">
      <ui-skeleton :height="100" />
    </div>
    <div class="col-6 mt-5">
      <ui-skeleton
        :height="20"
        class="w-40 d-block"
      />
      <ui-skeleton
        :height="20"
        class="w-80 d-block"
      />
    </div>
    <div class="col-3">
      <ui-skeleton :height="60" />
      <ui-skeleton :height="20" />
    </div>
    <div class="col-12">
      <ui-skeleton :height="30" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehiclePlaceholder',
};
</script>
