var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'phDatetimepicker_wrapper',
    {
      'flex-column': _vm.isVertical,
    },
  ]},[_c('div',{class:[
      'phDatetimepicker',
      {
        'my-4 w-100': _vm.isVertical,
      }
    ]},[_c('label',{staticClass:"phDatetimepicker_label",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('new_booking.picker.start'))+" ")]),_c('div',{staticClass:"phDatetimepicker_date"},[_c('Datetimepicker',{attrs:{"id":"startDate","btn-quantity":['1', 'day'],"disabled":_vm.isBookingStarted,"initial":_vm.datetimepickerManager.getStart(),"language":_vm.currentLanguage,"max":_vm.datetimepickerManager.getMaximumStart(),"min":_vm.datetimepickerManager.getMinimumStart(),"stepping":_vm.datetimepickerManager.getStepping(),"blur-on-change":"","format":"DD MMM YY","position":"left","data-test-id":"start_date"},on:{"changing":_vm.debouncedStartDate}}),_c('div',{staticClass:"phDatetimepicker_separator"}),_c('Datetimepicker',{attrs:{"id":"startTime","btn-quantity":[_vm.datetimepickerManager.getStepping(), 'minutes'],"disabled":_vm.isBookingStarted,"initial":_vm.datetimepickerManager.getStart(),"language":_vm.currentLanguage,"max":_vm.datetimepickerManager.getMaximumStart(),"min":_vm.datetimepickerManager.getMinimumStart(),"stepping":_vm.datetimepickerManager.getStepping(),"format":"HH:mm","position":"right","data-test-id":"start_time"},on:{"changing":_vm.debouncedStartDate}})],1)]),_c('div',{class:[
      'phDatetimepicker mt-2 mt-md-0',
      {
        'my-4 w-100': _vm.isVertical,
      }
    ]},[_c('label',{staticClass:"phDatetimepicker_label",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('new_booking.picker.end'))+" ")]),_c('div',{staticClass:"phDatetimepicker_date"},[_c('Datetimepicker',{attrs:{"id":"endDate","btn-quantity":['1', 'day'],"disabled":_vm.disabled,"initial":_vm.datetimepickerManager.getEnd(),"language":_vm.currentLanguage,"max":_vm.datetimepickerManager.getMaximumEnd(),"min":_vm.minimumEnd,"stepping":_vm.datetimepickerManager.getStepping(),"blur-on-change":"","format":"DD MMM YY","position":"left","data-test-id":"end_date"},on:{"changing":_vm.debouncedEndDate}}),_c('div',{staticClass:"phDatetimepicker_separator"}),_c('Datetimepicker',{attrs:{"id":"endTime","btn-quantity":[_vm.datetimepickerManager.getStepping(), 'minutes'],"disabled":_vm.disabled,"initial":_vm.datetimepickerManager.getEnd(),"language":_vm.currentLanguage,"max":_vm.datetimepickerManager.getMaximumEnd(),"min":_vm.minimumEnd,"stepping":_vm.datetimepickerManager.getStepping(),"format":"HH:mm","position":"right","data-test-id":"end_time"},on:{"changing":_vm.debouncedEndDate}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }