var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ui-modal',{attrs:{"header":_vm.$t('Bookings.DistanceFilter.modal.title'),"size":_vm.SIZES.small,"open":_setup.props.isVisible,"closable":false},on:{"close":function($event){return _setup.emits('close-modal')}}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('p',{staticClass:"my-3"},[_vm._v(" "+_vm._s(_vm.$t('Bookings.DistanceFilter.modal.text'))+" ")]),_c('ui-validate',{on:{"status":({ detail }) => _setup.isFormValid = detail.isValid}},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:({
          isRequired: {
            value: true,
            message: _vm.$t('Bookings.DistanceFilter.modal.error_mandatory'),
            whiteSpaceMessage: _vm.$t('Bookings.DistanceFilter.modal.error'),
          },
          isPattern: {
            pattern: _setup.VALIDATION_PATTERN.positiveInteger,
            message: _vm.$t('Bookings.DistanceFilter.modal.error'),
          },
        }),expression:"{\n          isRequired: {\n            value: true,\n            message: $t('Bookings.DistanceFilter.modal.error_mandatory'),\n            whiteSpaceMessage: $t('Bookings.DistanceFilter.modal.error'),\n          },\n          isPattern: {\n            pattern: VALIDATION_PATTERN.positiveInteger,\n            message: $t('Bookings.DistanceFilter.modal.error'),\n          },\n        }",modifiers:{"input":true}}],attrs:{"value":_setup.mileage,"label":"Km","placeholder":"0","name":"mileage"},on:{"changevalue":({ detail }) => _setup.mileage = detail}})],1)],1),_c('div',{staticClass:"d-flex flex-wrap justify-content-end",attrs:{"slot":"footer"},slot:"footer"},[_c('ui-button',_vm._b({attrs:{"disabled":!_setup.isFormValid || !_setup.mileage},on:{"clickbutton":_setup.saveEstimatedDistance}},'ui-button',_setup.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('Bookings.DistanceFilter.modal.button'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }