<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { nameSpace as TARIFF_MODULE_NAMESPACE } from '@/stores/Tariffs/TariffsModule';
import { nameSpace as ACCOUNT_MODULE_NAMESPACE } from '@/vue/stores/UserData/UserDataStore';
import { nameSpace as ProfileNameSpace } from '@Profile/store';
import personalProfileRoutesNames from '@Profile/Personal/router/routes-names';
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
import { USER_ROLES } from '@/constants/user.const';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'TariffExpiredModalComponent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { notifyError, notifySuccess } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      fetchButtonSpecs,
      notifyError,
      notifySuccess,
    };
  },
  computed: {
    ...mapState(TARIFF_MODULE_NAMESPACE, {
      defaultTariffStatus: state => get(state, 'defaultTariff.STATUS'),
    }),
    ...mapState(ACCOUNT_MODULE_NAMESPACE, {
      currentUserUuid: state => get(state, 'userData.uuid'),
      currentCompanyUuid: state => get(state, 'userData.company.uuid'),
      userData: state => get(state, 'userData'),
    }),
    ...mapGetters(ProfileNameSpace, ['isBusinessProfileActive']),
    modalTitle() {
      return this.shouldDisplaySubscriptionActions
        ? this.$t('Carsharing.Common.TariffExpiredModal.title')
        : this.$t('modal.tariff.road_block_business.title');
    },
    modalText() {
      return this.shouldDisplaySubscriptionActions
        ? this.$t('Carsharing.Common.TariffExpiredModal.text')
        : this.$t('modal.tariff.road_block_business.text');
    },
    doesCompanyPays() {
      return this.isBusinessProfileActive && get(this.userData, 'company.company_pays');
    },
    isCompanyAdmin() {
      return this.isBusinessProfileActive && get(this.userData, 'roles', []).includes(USER_ROLES.companyAdmin);
    },
    postDefaultTariffToEntity() {
      return this.isBusinessProfileActive ? this.postDefaultCompanyTariff : this.postDefaultTariff;
    },
    currentEntityUuid() {
      return this.isBusinessProfileActive ? this.currentCompanyUuid : this.currentUserUuid;
    },
    shouldDisplaySubscriptionActions() {
      return !this.isBusinessProfileActive || (this.isCompanyAdmin && this.doesCompanyPays);
    },
  },
  methods: {
    ...mapActions(TARIFF_MODULE_NAMESPACE, [
      'postDefaultTariff',
      'postDefaultCompanyTariff',
    ]),
    viewPricingPlans() {
      const routeName = this.isBusinessProfileActive ? CompanyAdminRoutesNames.tariffs : personalProfileRoutesNames.tariffs;
      this.$emit('close-modal');
      this.$router.push({
        name: routeName,
      });
    },
    onEmployeeCloseModal() {
      this.$emit('close-modal');
    },
    async addDefaultTariff() {
      await this.postDefaultTariffToEntity(this.currentEntityUuid);

      if (!this.defaultTariffStatus.ERROR) {
        this.$emit('close-modal');
        this.notifySuccess({
          text: this.$t('Carsharing.Common.Notifications.success.subscription_updated'),
        });
        this.onSuccess();
      } else {
        this.notifyError({
          textAction: this.$t('buttons.ok'),
          text: this.$t('notifications.whooops'),
        });
      }
    },
  },
};
</script>

<template>
  <ui-modal
    :header="modalTitle"
    :open="value"
    closable
  >
    <div slot="body">
      {{ modalText }}
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <template v-if="shouldDisplaySubscriptionActions">
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
          :disabled="defaultTariffStatus.LOADING"
          :loading="defaultTariffStatus.LOADING"
          @clickbutton="addDefaultTariff"
        >
          {{ $t('Carsharing.Common.TariffExpiredModal.continue_without_subscription') }}
        </ui-button>
        <ui-button
          v-bind="fetchButtonSpecs()"
          class="px-2"
          @clickbutton="viewPricingPlans"
        >
          {{ $t('Carsharing.Common.TariffExpiredModal.choose_tariff') }}
        </ui-button>
      </template>
      <ui-button
        v-else
        v-bind="fetchButtonSpecs()"
        class="px-2"
        @clickbutton="onEmployeeCloseModal"
      >
        {{ $t('buttons.got_it') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
