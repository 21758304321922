<script>
import { computed, ref } from 'vue';
import LegalDocumentsComponent from '@/domains/Document/components/LegalDocuments/LegalDocumentsComponents';
import { useAcceptLegalDocuments } from '@/composable/User/legalDocuments';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'MissingLegalDocumentsComponent',

  components: {
    LegalDocumentsComponent,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    onAccept: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },

  setup(props) {
    const {
      acceptedLegalDocuments,
      acceptLegalDocument,
      rejectLegalDocument,
      areAccepted,
    } = useAcceptLegalDocuments();

    const { fetchButtonSpecs } = useTheme();

    // Local data
    const isLoading = ref(false);
    // Local computed properties
    const isDisabled = computed(() => !areAccepted(props.documents));
    // Local methods
    const onClickAccept = async () => {
      isLoading.value = true;
      await props.onAccept({
        acceptedUuids: acceptedLegalDocuments.value,
      });
    };

    return {
      acceptedLegalDocuments,
      fetchButtonSpecs,
      isDisabled,
      isLoading,
      acceptLegalDocument,
      rejectLegalDocument,
      onClickAccept,
    };
  },
};
</script>

<template>
  <ui-modal
    :open="value"
    :size="SIZES.medium"
    :header="$t('BookingView.MissingLegalDocumentsComponent.title')"
    data-test-id="missing-legal-documents-modal"
    @input="value => $emit('input', value)"
  >
    <div slot="body">
      <p>{{ $t('BookingView.MissingLegalDocumentsComponent.description') }}</p>
      <LegalDocumentsComponent
        :accepted="acceptedLegalDocuments"
        :documents="documents"
        class="mt-3"
        @accept="({ value }) => acceptLegalDocument(value)"
        @reject="({ value }) => rejectLegalDocument(value)"
      />
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        :disabled="isLoading"
        :face="FACES.outline"
        class="mr-3"
        data-test-id="cancel-button"
        @clickbutton="onCancel"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isDisabled"
        :loading="isLoading"
        data-test-id="accept-button"
        @clickbutton="onClickAccept"
      >
        {{ $t('buttons.accept_solo') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
