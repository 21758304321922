import get from 'lodash/get';

import { DATE_FORMAT } from '@emobg/web-utils';

export function setDefaultDates(context) {
  const bookingStart = get(context, 'currentStartDateTime', { format: () => null });
  const deeplinkStart = get(context, 'externalBookingData.start');
  const queryStart = get(context, '$route.query.start');

  const bookingEnd = get(context, 'currentEndDateTime', { format: () => null });
  const deeplinkEnd = get(context, 'externalBookingData.end');
  const queryEnd = get(context, '$route.query.end');

  const start = bookingStart.format(DATE_FORMAT.filter) || deeplinkStart || queryStart;
  const end = bookingEnd.format(DATE_FORMAT.filter) || deeplinkEnd || queryEnd;

  return {
    start,
    end,
  };
}
