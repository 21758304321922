<script>
import each from 'lodash/each';
import get from 'lodash/get';
import set from 'lodash/set';
import keys from 'lodash/keys';

import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'TariffWillExpireModalComponent',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    expiryDate: {
      required: true,
      type: String,
      default: '',
    },
    onViewPricingPlans: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
  data() {
    return {
      buttons: {
        close: {
          attributes: {
            ...this.fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY }),
            disabled: false,
            loading: false,
          },
          listeners: {
            click: () => {
              this.$emit('input', false);
            },
          },
          text: this.$t('buttons.close'),
        },
        viewPlans: {
          attributes: {
            ...this.fetchButtonSpecs(),
            disabled: false,
            loading: false,
          },
          listeners: {
            click: async () => {
              this.resetButtons('viewPlans');
              await this.onViewPricingPlans();
              this.setButtonToLoading();
            },
          },
          text: this.$t('Carsharing.Common.TariffWillExpireModal.choose_tariff'),
        },
      },
    };
  },
  methods: {
    resetButtons() {
      each(keys(this.buttons), buttonKey => {
        set(this.buttons[buttonKey], 'attributes.disabled', false);
        set(this.buttons[buttonKey], 'attributes.loading', false);
      });
    },
    setButtonToLoading(buttonClicked) {
      each(keys(this.buttons), buttonKey => {
        set(this.buttons[buttonKey], 'attributes.disabled', true);
      });
      const buttonToLoading = get(this.buttons, buttonClicked);
      if (buttonToLoading) {
        set(buttonToLoading, 'attributes.loading', true);
      }
    },
  },
};
</script>

<template>
  <ui-modal
    :size="SIZES.small"
    :open="value"
    :header="$t('Carsharing.Common.TariffWillExpireModal.title')"
  >
    <div slot="body">
      <p v-html="$t('Carsharing.Common.TariffWillExpireModal.text', { expiry_date: expiryDate })" />
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-for="(button, index) in buttons"
        :key="`button-${index}`"
        :class="[
          'my-1',
          {
            'ml-3': index
          }
        ]"
        v-bind="button.attributes"
        v-on="button.listeners"
      >
        {{ button.text }}
      </ui-button>
    </div>
  </ui-modal>
</template>
